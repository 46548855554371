import AboutPage from "../Components/About-Page";

export default function Home() {
  return (
    <>
      <div>
        <AboutPage />
      </div>
    </>
  );
}
