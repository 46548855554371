import LoginForm from "../Components/LoginForm";
export default function Auth() {
  return (
    <>
      <div>
        <LoginForm />
      </div>
    </>
  );
}
